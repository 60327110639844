import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";

const {ScrollTrigger} = require("gsap.ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

class SomethingReal extends PewComponent {
  init() {
    if (!this.isMobile()) {
      this.registerScrollParallax();
      this.registerTitlePinning();
    }
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  registerScrollParallax() {
    const $figures = this.element.find('figure');
    if($figures.length){
      $figures.each((i, figure) => {
        const yPercent = i % 2 === 0 ? -160 : -100;
        const scrub = i % 2 === 0 ? .8 : .4;
        gsap.to(figure, {
          yPercent: yPercent,
          ease: "none",
          scrollTrigger: {
            trigger: this.element,
            scrub: scrub,
            //markers: true,
          },
        });

      });
    }
  }

  getTitleAnimation() {
    const $sectionTitle = $('.section-title-images');
    const $title = $sectionTitle.find('h2.h1-like');
    const $subTitle = $sectionTitle.find('h2.has-secondary-color');

    //Create a gsap animation that will animate the $title from opacity 0 to 1, and from a letter spacing of 20px to its original value and a color of #fff to its original value
    const timeline = gsap.timeline();
    timeline
      .set($title, {color: '#fff'})
      .set($subTitle, {opacity: 0})
      .fromTo(
        $title,
        {
          opacity: 0,
          letterSpacing: "80px",
          color: '#fff'
        },
        {
          opacity: 1,
          letterSpacing: "1px",
          duration: 4, // Animation duration in seconds
          ease: "power3.out",
          autoRound: false
        }
      )
      .to(
        $title,
        {
          color: "#8A1733",
          duration: 1,
          ease: "power1.out"
        },
        '-=.1'
      )
      .to(
        $subTitle,
        {
          opacity: 1,
          duration: .5,
          ease: "power1.out"
        },
        '-=.6'
      );
    //console.log(timeline);

    return timeline;
  }

  registerTitlePinning() {
    const $titleWrap = $('.section-title-images');
    const $logo = $('.inner-header .logo');
    ScrollTrigger.create({
      trigger: '.section-images',
      start: 'top 25%',
      animation: this.getTitleAnimation(),
      //markers: true,
      toggleActions: "play pause resume reset",
      endTrigger:'.section-images',
      end: 'bottom top',
      onEnter: () => { $titleWrap.fadeIn(); gsap.to($logo,{autoAlpha: 0, duration: .3, ease: 'power1.out'}); },
      onLeave: () => { $titleWrap.fadeOut(); },
      onEnterBack: () => { $titleWrap.fadeIn(); },
      onLeaveBack: () => { $titleWrap.fadeOut(); gsap.to($logo,{autoAlpha: 1, duration: .3, ease: 'power1.out'}); }
    });
  }
}

window.pew.addRegistryEntry({
  key: 'section-something-real',
  domSelector: '.section-images .wp-block-wwp-gutenberg-utils-container-block',
  classDef: SomethingReal
});
