import {SliderComponent} from "../SliderDefault/SliderComponent";

export class SliderStunningCards extends SliderComponent {
  constructor(element, passedOptions) {
    passedOptions = {
      mode: 'carousel',
      controlsPosition: "top",
      navPosition: "bottom",
      prevButton: false,
      autoHeight: true,
      arrowKeys: true,
      loop: false,
      rewind: true,
      responsive: {
        768: {
          disable: true
        }
      }
    };
    super(element, passedOptions);
  }
}

window.pew.addRegistryEntry({
  key: 'slider-stunning-cards',
  domSelector: '.patterns-list > div',
  classDef: SliderStunningCards
});
