import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";

const {ScrollTrigger} = require("gsap.ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

class OrdinarySpecial extends PewComponent {
  init() {
    if (!this.isMobile()) {
      this.registerTitleAnimation();
    }
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  registerTitleAnimation() {
    gsap.from(this.element.find('.wp-block-column'), {
      autoAlpha: 0,
      yPercent: 10,
      duration: .5,
      ease: "power3.inOut",
      stagger: .25,
      scrollTrigger: {
        trigger: this.element.find('.wp-block-columns'),
        start: "top 75%",
        endTrigger: this.element.parent(),
        end: "bottom 75%",
        toggleActions: 'restart reverse restart reverse',
        //markers: true
      }
    });
  }
}

window.pew.addRegistryEntry({
  key: 'section-ordinary-special',
  domSelector: '.section-ordinary .wp-block-wwp-gutenberg-utils-container-block',
  classDef: OrdinarySpecial
});
