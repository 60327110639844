import {PewComponent} from "../../../assets/raw/js/components/pew-component";

export class Menu extends PewComponent {

  constructor(element, options) {
    let defaultOptions = {
      classToToggle: 'is-opened',
      depth: 0
    };
    super(element, Object.assign(defaultOptions, options));
  }

  self($thisLi, subMenuOptions) {
    return new this.constructor($thisLi, subMenuOptions);
  }

  init() {
    //this.opened = this.element.css('display') !== 'none' && this.element.css('visibility') !== 'hidden';
    this.opened = this.element.hasClass(this.options.classToToggle);
    this.registerEvents();
    if (this.options.depth > 0) {
      this.addSubMenuNavTo(this.element);
    }
  }

  registerEvents() {
    this.registerSubMenuOpener();
  }

  toggle() {
    //console.log(this.opened);
    if (this.opened) {
      this.close()
    } else {
      this.open();
    }
  }

  toggleClasses() {
    if (this.opened) {
      this.element.addClass(this.options.classToToggle);
    } else {
      this.element.removeClass(this.options.classToToggle);
    }
  }

  open() {
    this.startOpen(() => {
      this.endOpen();
    });
  }

  startOpen(cb) {
    cb && cb();
  }

  endOpen() {
    this.opened = true;
    this.toggleClasses();
    this.checkIfSubMenuShouldBeOpened();
  }

  close() {
    this.startClose(() => {
      this.endClose();
    });
  }

  startClose(cb) {
    cb && cb();
  }

  endClose() {
    this.opened = false;
    this.toggleClasses();
  }

  registerSubMenuOpener() {
    this.element.find('> ul > li > a').on('click', (e) => {
      let $thisLink = $(e.currentTarget),
        $thisLi = $thisLink.parent();

      if ($thisLi.find('.children').length > 0) {
        e.preventDefault();
        this.handleSubMenu($thisLi);
      }
    });
  }

  handleSubMenu($thisLi) {
    this.toggleSubMenu($thisLi);
  }

  createSubMenu($thisLi) {
    var self = this;
    if (!$thisLi.data('menu')) {
      let subMenuOptions = {
        'depth': self.options.depth + 1
      };
      let thisMenu = this.self($thisLi, subMenuOptions);
      $thisLi.data('menu', thisMenu);
    }
  }

  toggleSubMenu($thisLi) {
    if (!$thisLi.data('menu')) {
      this.createSubMenu($thisLi);
    }
    $thisLi.data('menu').toggle();
  }

  addSubMenuNavTo($elt) {
    if ($elt.find('.mobile-nav-links').length <= 0) {
      let subMenuNav = '<ul class="mobile-nav-links">' +
        '<li class="back-btn"><button>Retour</button></li>' +
        '<li class="parent-page"><a href="' + this.element.find('> a').attr('href') + '"><span>' + this.element.find('> a').text() + '</span> <span>(Voir la page)</span></a></li>' +
        '</ul>';
      $elt.find('> ul').prepend(subMenuNav);
      $elt.find('> ul button').on('click', (e) => {
        this.close();
      });
    }
  }

  checkIfSubMenuShouldBeOpened() {
    let hasAlreadyDrilled = this.element.data('already-drilled');
    if (hasAlreadyDrilled !== 1) {
      let $activeSubMenu = this.element.find('> ul > li.current_page_ancestor');
      if ($activeSubMenu.length > 0) {
        this.createSubMenu($activeSubMenu);
        $activeSubMenu.data('menu').open();
      }
      this.element.data('already-drilled', 1);
    }
  }

  static updateSelectedItem(newId) {
    const $menu = $('.main-menu'),
      classes = {
        currentItem: ['current-menu-item', 'current_page_item'],
        currentParent: 'current_page_parent',
        currentAncestor: 'current_page_ancestor'
      },
      i18n = window.wonderwp && window.wonderwp.i18n ? window.wonderwp.i18n : {},
      labels = {
        currentItem: i18n.current_page_item ? i18n.current_page_item : 'current page item',
        currentParent: i18n.current_page_parent ? i18n.current_page_parent : 'current page parent',
        currentAncestor: i18n.current_page_ancestor ? i18n.current_page_ancestor : 'current page ancestor',
      };
    //console.log('[Menu] ', 'updateSelectedItem', $menu, newId);

    /**
     * a) Find current menu item (it's an li)
     * b) Remove its current_page_item class
     * c) Find the link inside, remove its aria label
     * d) Find its parent, remove the current_page_parent class
     * e) Find its ancestors, remove the current_page_ancestor class
     * f) Find the link inside, remove its aria label
     */

      //a)
    const currentItemSelector = classes.currentItem.map(c => {
        return '.' + c;
      }).join(',');
    let $currentItem = $menu.find(currentItemSelector);

    if ($currentItem.length) {
      //b)
      $currentItem.removeClass(classes.currentItem.join(' '));
      //c)
      $currentItem.find('>a').removeAttr('aria-label');
      const $ancestors = $currentItem.parents('li');
      if ($ancestors.length) {
        $ancestors.each((i, ancestor) => {
          let $thisAncestor = $(ancestor);
          if (i === 0) {
            //d)
            $thisAncestor.removeClass(classes.currentParent);
          }
          //e)
          $thisAncestor.removeClass(classes.currentAncestor);
          //f)
          $thisAncestor.find('>a').removeAttr('aria-label');
        });
      }
    }

    /**
     * g) Then if we have a corresponding menu element with id newId (it's an li)
     * h) Add the current_page_item class
     * i) Find the link inside, add an aria label : concat the link text + the current_page_item i18n label
     * j) Find its parent, add the current_page_parent class
     * k) Find the link inside, add an aria label : concat the link text + the current_page_parent i18n label
     * l) Find its ancestors, add the current_page_ancestor class
     * m) Find the link inside, add aria label : concat the link text + the current_page_ancestor i18n label
     */

    //g)
    if (newId) {
      //$menu.find('[data-pid="' + newId + '"]').attr('aria-current', 'page').parent().addClass('current-menu-item', 'current_page_item');
      $currentItem = $menu.find('[data-pid="' + newId + '"]').parent();
      if ($currentItem.length) {
        //h)
        $currentItem.addClass(classes.currentItem.join(' '));
        //i)
        const $subLink = $currentItem.find('>a');
        let newAriaLabel = $subLink.text() + ' (' + labels.currentItem + ')';
        $subLink.attr('aria-label', newAriaLabel);

        const $newAncestors = $currentItem.parents('li');
        if ($newAncestors.length) {
          $newAncestors.each((i, ancestor) => {
            let $thisAncestor = $(ancestor),
              $thisAncestorLink = $thisAncestor.find('>a');
            newAriaLabel = '';
            if (i === 0) {
              //j)
              $thisAncestor.addClass(classes.currentParent);
              //k)
              newAriaLabel = $thisAncestorLink.text() + ' (' + labels.currentParent + ')' + ' (' + labels.currentAncestor + ')';
            } else {
              //m)
              newAriaLabel = $thisAncestorLink.text() + ' (' + labels.currentAncestor + ')';
            }
            //l)
            $thisAncestor.addClass(classes.currentAncestor);

            $thisAncestorLink.attr('aria-label', newAriaLabel);
          });
        }

      }
    }
  }

  log(msg) {
    if (this.debug) {
      console.log('[Menu] ', ...arguments);
    }
  }

}
