import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";

const {ScrollTrigger} = require("gsap.ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

class WhereToFind extends PewComponent {
  init() {
    if (!this.isMobile()) {
      this.registerTitleAnimation();
      this.registerBubbleAnimation();
      this.registerSubtitlesAnimation();
      this.registerMapMarkersAnimation();
    }
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  registerTitleAnimation() {
    const $title = this.element.find('.wp-block-cover__inner-container');

    const tween = gsap.from($title, {
      autoAlpha: 0,
      xPercent: -2,
      duration: .3,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: $title,
        start: "bottom bottom",
        toggleActions: 'restart none none reverse',
        //markers: true
      }
    });
  }

  registerBubbleAnimation() {
    const $bubble = this.element.find('.encadre-circle-white');

    const tween = gsap.from($bubble, {
      autoAlpha: 0,
      scale: .5,
      duration: .3,
      ease: "back.out",
      scrollTrigger: {
        trigger: $bubble,
        start: "bottom bottom",
        toggleActions: 'restart none none reverse',
        //markers: true
      }
    });
  }

  registerSubtitlesAnimation() {
    const $titles = this.element.find('h3');

    $titles.each((i, title) => {
      gsap.from(title, {
        autoAlpha: 0,
        yPercent: 10,
        duration: .5,
        ease: "power3.inOut",
        scrollTrigger: {
          trigger: title,
          start: "top 80%",
          toggleActions: 'restart none none reverse',
          //markers: true
        }
      });
    });
  }

  registerMapMarkersAnimation() {
    const $map = this.element.find('.map-background'),
      $markers = this.element.find('.marker'),
      $mapPopin = this.element.find('.map-popin');

    const timeline = gsap.timeline();
    timeline
      .from($markers, {
        autoAlpha: 0,
        yPercent: 10,
        duration: .5,
        ease: "power3.inOut",
        stagger: .05,
      })
      .to($mapPopin, {
        autoAlpha: 1,
        scale: 1,
        duration: .5,
        ease: "back.out",
      })
    ;

    ScrollTrigger.create({
      trigger: $map,
      animation: timeline,
      start: "center center",
      toggleActions: 'restart none none reverse',
      //markers: true
    });
  }
}

window.pew.addRegistryEntry({
  key: 'section-where',
  domSelector: '.section-where',
  classDef: WhereToFind
});
