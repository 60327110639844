import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";

const {ScrollTrigger} = require("gsap.ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

class GreatReasons extends PewComponent {
  init() {
    if (!this.isMobile()) {
      this.registerHorizontalScroll();
    }
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  registerHorizontalScroll() {
    const
      $panelsContainer = this.element.find("> .wp-block-group"),
      panels = gsap.utils.toArray($panelsContainer.find(">*"));

    let wrapperWidth = 0;
    panels.forEach((panel) => {
      wrapperWidth += panel.offsetWidth;
    });
    $panelsContainer.css('width', wrapperWidth - innerWidth + 'px');

    const tween = gsap.to($panelsContainer, {
      xPercent: -100,
      ease: "none",
      scrollTrigger: {
        trigger: this.element,
        //markers: true,
        pin: true,
        start: "top top",
        scrub: 1,
        end: () => {
          let wrapperWidth = 0;
          panels.forEach((panel) => {
            wrapperWidth += panel.offsetWidth;
          });
          return "+=" + ( wrapperWidth - innerWidth);
        }
      }
    });
  }
}

window.pew.addRegistryEntry({
  key: 'section-great-reasons',
  domSelector: '.section-reasons',
  classDef: GreatReasons
});
