import {tns} from "tiny-slider/dist/tiny-slider";
import {PewComponent} from "../../../../assets/raw/js/components/pew-component";

export class SliderComponent extends PewComponent {
  constructor(element, passedOptions) {
    // see http://ganlanyuan.github.io/tiny-slider/#options
    let defaultOptions = {
      mode: 'gallery',
      slideBy: 'page',
      autoplay: false,
      autoplayPosition: 'bottom',
      autoplayButton: false,
      nav: true,
      speed: 500,
      autoplayTimeout: 3000
    };
    super(element, Object.assign(defaultOptions, passedOptions));

    /*if (window.wonderwp && window.wonderwp.i18n && window.wonderwp.i18n.slider) {
      const keysProvided = ['nextText', 'prevText', 'startText', 'stopText', 'goToPaneText', 'currentPaneText'];
      for (let i in keysProvided) {
        let keyProvided = keysProvided[i];
        if (window.wonderwp.i18n.slider[keyProvided]) {
          SliderOptions[keyProvided] = window.wonderwp.i18n.slider[keyProvided];
        }
      }
    }*/
  }

  init() {
    if (!this.options.container) {
      this.options.container = this.element[0];
    }
    this.slider = tns(this.options);
  }

}

window.pew.addRegistryEntry({key: 'wdf-slider', domSelector: '[class$="wdf-slider"]', classDef: SliderComponent});
