import {PewComponent} from "../../../assets/raw/js/components/pew-component";

class ReadmoreToggle extends PewComponent {

  init() {
    const $link = this.element.find('.readmore-toggle');
    if (!$link.attr('data-toggle-expand')) {
      $link.attr('data-toggle-expand', $link.text());
    }
    if (!$link.attr('data-toggle-reduce')) {
      $link.attr('data-toggle-reduce', 'Réduire');
    }
    this.initToggle();
  }

  initToggle() {
    this.element.find('.readmore-toggle').on('click', (e) => {
      e.preventDefault();
      let $link = $(e.currentTarget);
      let $desc = this.element.find('.readmore-target');
      if ($desc.css('display') === 'none') {
        $desc.slideDown().attr('aria-hidden', false);
        $desc.focus();
        //$link.attr('aria-label',$link.attr('aria-label').replace('(cliquer pour ouvrir)','(cliquer pour fermer)'));
        $link.attr('aria-expanded', true);
        $link.text($link.attr('data-toggle-reduce'));
      } else {
        $desc.slideUp().attr('aria-hidden', true);
        //$link.attr('aria-label',$link.attr('aria-label').replace('(cliquer pour fermer)','(cliquer pour ouvrir)'));
        $link.attr('aria-expanded', false);
        $link.text($link.attr('data-toggle-expand'));
      }
      $link.toggleClass('open');
    });
  }
}

window.pew.addRegistryEntry({key: 'readmore-toggle', domSelector: '.readmore-context', classDef: ReadmoreToggle});
