import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";

const {ScrollTrigger} = require("gsap.ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

class StackTopSection extends PewComponent {
  init() {
    if (!this.isMobile()) {
      this.registerScrollAnimation();
    }
  }
  isMobile() {
    return window.innerWidth < 768;
  }
  registerScrollAnimation() {
    const panel = this.element[0];
    let scrollTriggerOpts = {
      trigger: panel,
      start: () => panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
      pin: true,
      pinSpacing: false,
      toggleClass: "pinned",
      //markers: true
    };
    if(this.element.hasClass('section-images')){
      scrollTriggerOpts.end = 'bottom -50%';
    }
    if(this.element.hasClass('section-ordinary')){
      scrollTriggerOpts.start = 'center center';
      scrollTriggerOpts.end = 'bottom -50%';
    }
    if(this.element.hasClass('section-difference')){
      scrollTriggerOpts.start = 'center 40%';
    }
    ScrollTrigger.create(scrollTriggerOpts);
  }
}
window.pew.addRegistryEntry({key: 'stack-top-section', domSelector: '.stack-top-section', classDef: StackTopSection});

class StackBottomSection extends PewComponent {
  init() {
    if (!this.isMobile()) {
      this.registerScrollAnimation();
    }
  }
  isMobile() {
    return window.innerWidth < 768;
  }
  registerScrollAnimation() {
    const panel = this.element[0];
    gsap.to(panel, {
      yPercent: -100,
      scrollTrigger: {
        trigger: panel,
        start: () => panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
        scrub: true,
        pin: true
      }
    });
  }
}
//window.pew.addRegistryEntry({key: 'stack-bottom-section', domSelector: '.stack-bottom-section', classDef: StackBottomSection});
