import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";

const {ScrollTrigger} = require("gsap.ScrollTrigger");
gsap.registerPlugin(ScrollTrigger);

class TheDifference extends PewComponent {
  init() {
    if (!this.isMobile()) {
      this.registerTitleAnimation();
    }
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  registerTitleAnimation() {
    const $coverInner = this.element.find('.wp-block-cover__inner-container');

    const tween = gsap.from($coverInner, {
      autoAlpha: 0,
      yPercent: 10,
      duration: .5,
      ease: "power3.inOut",
      scrollTrigger: {
        trigger: $coverInner,
        start: "center bottom",
        end: "bottom 15%",
        toggleActions: 'restart reverse restart reverse',
        //markers: true
      }
    });
  }
}

window.pew.addRegistryEntry({
  key: 'section-the-difference',
  domSelector: '.section-difference .wp-block-cover',
  classDef: TheDifference
});
