import {PewComponent} from "../../../assets/raw/js/components/pew-component";
import {gsap} from "gsap";

const cursorDefinitions = [
  {
    selector: 'a, button, label, .nav-button',
    classToToggle: 'link-hover-cursor'
  },
  {
    selector: 'a[target="_blank"]',
    classToToggle: 'link-external-cursor'
  },
  {
    selector: '.video-link, .video-large, .wdf-modal[data-modaal-type="video"]',
    classToToggle: 'video-link-cursor'
  },
  {
    selector: '.site-footer, .gradient-grey-to-white, .gradient-white-to-grey',
    classToToggle: 'non-difference-cursor'
  }
];

export default class CustomCursorComponent extends PewComponent {
  init() {
    this.hoverState = false;
    if ($('html').hasClass('no-touch')) {
      this.createPointer();
      this.activatePointerMovement();
      this.registerCursorDefinitions(cursorDefinitions);
      const EventManager = window.EventManager || $(document);
      EventManager.on('PageUpdate.JS.initComponents', (event, eventData) => {
        this.registerCursorDefinitions(cursorDefinitions);
      });
    }
  }

  createPointer() {
    const pointerCode = `
    <span class="custom-cursor-elt custom-cursor-pointer"></span>
    <span class="custom-cursor-elt custom-cursor-circle"></span>`;
    this.element.append(pointerCode);
    this.pointer = this.element.find('.custom-cursor-pointer')[0];
    this.circle = this.element.find('.custom-cursor-circle')[0];
    this.cursorElt = this.element.find('.custom-cursor-elt');
  }

  activatePointerMovement() {
    this.element.on('mousemove.customCursor', (e) => {
      this.positionFollower(e);
    });
  }

  positionFollower(event) {

    gsap.to(this.pointer, {
      duration: .05,
      top: event.clientY,
      left: event.clientX
    });
    gsap.to(this.circle, {
      duration: .5,
      top: event.clientY,
      left: event.clientX
    });
  };

  registerCursorDefinitions(cursorDefinitions) {

    for (let i in cursorDefinitions) {
      let def = cursorDefinitions[i];
      this.element.find(def.selector)
        .off('mouseenter.customCursor')
        .off('mouseleave.customCursor')
        .on('mouseenter.customCursor', () => {
          this.hoverState = true;
          this.cursorElt.addClass(def.classToToggle);
        })
        .on('mouseleave.customCursor', () => {
          this.hoverState = false;
          this.cursorElt.removeClass(def.classToToggle);
        });
    }

    this.element.find('[data-custom-cursor-class]')
      .off('mouseenter.customCursor')
      .off('mouseleave.customCursor')
      .on('mouseenter.customCursor', (e) => {
        this.hoverState = true;
        this.tempCssClassToToggle = $(e.currentTarget).data('custom-cursor-class');
        this.cursorElt.addClass(this.tempCssClassToToggle);
      })
      .on('mouseleave.customCursor', (e) => {
        this.hoverState = true;
        this.cursorElt.removeClass(this.tempCssClassToToggle);
      });

    this.element.find('#header')
      .off('mouseenter.customCursor')
      .off('mouseleave.customCursor')
      .on('mouseenter.customCursor', () => {
        if (!$('body').hasClass('has-opened-menu')) {
          this.cursorElt.addClass('non-difference-cursor');
        }
      })
      .on('mouseleave.customCursor', () => {
        if (!$('body').hasClass('has-opened-menu')) {
          this.cursorElt.removeClass('non-difference-cursor');
        }
      });

    this.element.find('.nav-button')
      .off('click.customCursor')
      .on('click.customCursor', () => {
        if (!$('body').hasClass('has-opened-menu')) {
          this.cursorElt.removeClass('non-difference-cursor');
        } else {
          this.cursorElt.addClass('non-difference-cursor');
        }
      });

  }
}

window.pew.addRegistryEntry({key: 'CustomCursorComponent', domSelector: '.custom-cursor-wrap', classDef: CustomCursorComponent});
